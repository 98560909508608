// @flow strict
import React from "react";
import { Link } from "gatsby";
import { getContactHref } from "../../../utils";
import styles from "./Author.module.scss";
import { useSiteMetadata } from "../../../hooks";

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles["author"]}>
      <p className={styles["author__bio"]}>
        {author.bio}

        <Link className={styles["author__home-button"]} to="/">
          {author.name} Homepage
        </Link>
      </p>
    </div>
  );
};

export default Author;
